import React from "react";
import Container from "@material-ui/core/Container";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import { NavLink } from "react-router-dom";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import InstagramIcon from "@material-ui/icons/Instagram";
import FacebookIcon from "@material-ui/icons/Facebook";

const useStyles = makeStyles((theme) => {
  return {
    section: {
      padding: "2rem 0",
    },
    coloredSection: {
      background: "#ffeae9",
    },
    nav: {
      textDecoration: "none",
    },
    navLabel: {
      transition: "0.2s ease-in-out",
      "&:hover": {
        color: "#e75c6b",
        transition: "0.2s ease-in-out",
      },
    },
  };
});

const Home = (props) => {
  const classes = useStyles();

  const openPage = (url) => {
    const newWindow = window.open(url, "_blank", "noopener,noreferrer");
    if (newWindow) newWindow.opener = null;
  };

  return (
    <section className={`${classes.section} ${classes.coloredSection}`}>
      <Container>
        <Grid container>
          <Grid item xs={12}>
            <Box textAlign="center" mb={2}>
              <InstagramIcon
                style={{ marginLeft: 8, marginRight: 8, cursor: "pointer" }}
                onClick={() =>
                  openPage("https://www.instagram.com/douxpatisserie.mdn")
                }
              />
              <FacebookIcon
                style={{ marginLeft: 8, marginRight: 8, cursor: "pointer" }}
                onClick={() =>
                  openPage("https://www.facebook.com/douxpatisserie.mdn")
                }
              />
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box textAlign="center">
              <NavLink className={classes.nav} to="/terms-of-use">
                <Typography
                  display="inline"
                  className={classes.navLabel}
                  color="textPrimary"
                >
                  Terms of Use
                </Typography>
              </NavLink>
              <Typography
                style={{ padding: "0 0.5rem" }}
                display="inline"
                className={classes.navLabel}
                color="textPrimary"
              >
                |
              </Typography>
              <NavLink className={classes.nav} to="/privacy-policy">
                <Typography
                  display="inline"
                  className={classes.navLabel}
                  color="textPrimary"
                >
                  Privacy Policy
                </Typography>
              </NavLink>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box mt="1rem" textAlign="center">
              <Typography color="textPrimary">
                Copyright {new Date().getFullYear()} Doux
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </section>
  );
};

export default Home;
