import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Collapse from "@material-ui/core/Collapse";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import { useHistory } from "react-router-dom";
import _ from "lodash";

const useStyles = makeStyles((theme) => ({
  nested: {
    paddingLeft: theme.spacing(4),
  },
}));

export default function SideDrawer(props) {
  const classes = useStyles();
  const history = useHistory();

  const [open, setOpen] = React.useState(false);

  const handleClick = (event) => {
    event.stopPropagation();
    setOpen(!open);
  };

  return (
    <Drawer
      className={classes.drawer}
      anchor="left"
      open={props.isOpened}
      onClose={props.toggleDrawer(false)}
      PaperProps={{
        style: {
          backgroundColor: "#ffeae9",
        },
      }}
    >
      <Box
        width={250}
        role="presentation"
        onClick={props.toggleDrawer(false)}
        onKeyDown={props.toggleDrawer(false)}
      >
        <List>
          <ListItem button onClick={() => history.push("/")}>
            <ListItemText primary="Home" />
          </ListItem>
          <ListItem button onClick={handleClick}>
            <ListItemText primary="Products" />
            {open ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <List disablePadding>
              {props.categories.map((category) => (
                <ListItem
                  key={category._id}
                  button
                  className={classes.nested}
                  onClick={() =>
                    history.push(`/products/${_.kebabCase(category.name)}`)
                  }
                >
                  <ListItemText primary={category.name} />
                </ListItem>
              ))}
              <ListItem
                button
                className={classes.nested}
                onClick={() => history.push(`/accessories`)}
              >
                <ListItemText primary="Accessories" />
              </ListItem>
            </List>
          </Collapse>
          <ListItem
            button
            onClick={() => history.push("/birthday-slices-cake")}
          >
            <ListItemText primary="Birthday Slices Cake" />
          </ListItem>
          <ListItem button onClick={() => history.push("/custome-cake")}>
            <ListItemText primary="Custome Cake" />
          </ListItem>
          <ListItem
            button
            onClick={() => history.push("/two-tier-mille-crepes")}
          >
            <ListItemText primary="Two Tier Mille Crepes" />
          </ListItem>
          <ListItem button onClick={() => history.push("/contact")}>
            <ListItemText primary="Contact" />
          </ListItem>
          <ListItem button onClick={() => history.push("/about-us")}>
            <ListItemText primary="About Us" />
          </ListItem>
        </List>
      </Box>
    </Drawer>
  );
}
