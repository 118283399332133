import React, { Suspense, useState, useEffect } from "react";
import {
  unstable_createMuiStrictModeTheme as createTheme,
  ThemeProvider,
  responsiveFontSizes,
} from "@material-ui/core/styles";
import { Switch, Route } from "react-router-dom";

import Footer from "./components/Footer";
import usePageTracking from "./components/usePageTracking";
import TopBar from "./components/TopBar";
import SideDrawer from "./components/SideDrawer";

const Home = React.lazy(() => import("./pages/Home"));
const Cart = React.lazy(() => import("./pages/Cart"));
const BirthdaySlicesCake = React.lazy(() =>
  import("./pages/BirthdaySlicesCake")
);
const Products = React.lazy(() => import("./pages/Products"));
const ProductDetails = React.lazy(() => import("./pages/ProductDetails"));
const Accessories = React.lazy(() => import("./pages/Accessories"));
const AccessoryDetails = React.lazy(() => import("./pages/AccessoryDetails"));
const NotFound = React.lazy(() => import("./pages/NotFound"));
const Contact = React.lazy(() => import("./pages/Contact"));
const AboutUs = React.lazy(() => import("./pages/AboutUs"));
const PrivacyPolicy = React.lazy(() => import("./pages/PrivacyPolicy"));
const TermsOfUse = React.lazy(() => import("./pages/TermsOfUse"));
const CustomeCake = React.lazy(() => import("./pages/CustomeCake"));
const TwoTierMilleCrepes = React.lazy(() =>
  import("./pages/TwoTierMilleCrepes")
);

let theme = createTheme({
  palette: {
    primary: {
      main: "#e75c6b",
    },
    secondary: {
      main: "#ffeae9",
    },
    text: {
      primary: "#493437",
      secondary: "#7a7a7a",
    },
  },
  typography: {
    fontFamily: ["Quicksand", '"Open Sans"'].join(","),
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightBold: 700,
  },
  breakpoints: {
    keys: ["xs", "sm", "tablet", "md", "lg", "xl"],
    values: {
      xs: 0,
      sm: 600,
      tablet: 769,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
});
theme = responsiveFontSizes(theme);

const updateCartLocalStorage = (cart) => {
  if (cart) {
    const mappedCart = cart.map((cartItem) => {
      return {
        type: cartItem.type,
        item_id: cartItem.item_id,
        qty: cartItem.qty,
        selectedSize: cartItem.selectedSize
          ? cartItem.selectedSize._id
          : undefined,
        bscSlices:
          cartItem.bscSlices &&
          cartItem.bscSlices.map((bscSlice) => {
            return {
              item_id: bscSlice.item._id,
              qty: bscSlice.qty,
            };
          }),
        slices: cartItem.slices.map((slice) => {
          return {
            _id: slice._id,
            selectedFlavours: slice.selectedFlavours.map((selectedFlavour) => {
              return {
                flavour_id: selectedFlavour.flavour._id,
                qty: selectedFlavour.qty,
              };
            }),
          };
        }),
        details: cartItem.details.map((detail) => {
          return {
            _id: detail._id,
            items: detail.items.map((item) => {
              return {
                _id: item._id,
              };
            }),
          };
        }),
        accessories: cartItem.accessories.map((acc) => acc._id),
        customFondant: cartItem.customFondant,
      };
    });

    localStorage.setItem("cart", JSON.stringify(mappedCart));
  } else {
    localStorage.removeItem("cart");
  }
};

function App() {
  usePageTracking();

  const [isSideDrawerOpened, setIsSideDrawerOpened] = useState(false);
  const [categories, setCategories] = useState([]);
  const [cart, setCart] = useState();

  useEffect(() => {
    fetchCategories();
    fetchCart();
  }, []);

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setIsSideDrawerOpened(open);
  };

  const fetchCategories = async () => {
    const response = await fetch(`${process.env.REACT_APP_API_URI}/categories`);
    const data = await response.json();

    setCategories(data);
  };

  const fetchCart = async () => {
    let processedCart = [];

    if (localStorage.getItem("cart")) {
      try {
        const savedCart = JSON.parse(localStorage.getItem("cart"));

        let itemIds = [];
        let accessoryIds = [];

        savedCart.forEach((cartItem) => {
          if (cartItem.type === "Bsc") {
            cartItem.bscSlices.forEach((bscSlice) => {
              itemIds.push(bscSlice.item_id);
            });
            cartItem.accessories.forEach((accId) => {
              accessoryIds.push(accId);
            });
          } else if (cartItem.type === "Item") {
            itemIds.push(cartItem.item_id);
          } else {
            accessoryIds.push(cartItem.item_id);
          }
        });

        const response = await fetch(
          `${process.env.REACT_APP_API_URI}/items?ids=${itemIds}&include=slices,selections,accessories,sizes`
        );
        const items = await response.json();
        const response2 = await fetch(
          `${process.env.REACT_APP_API_URI}/accessories?ids=${accessoryIds}`
        );
        const accessoriesData = await response2.json();

        for (const cartItem of savedCart) {
          if (cartItem.type === "Bsc") {
            // check if bsc slices item id changed or not
            let isValid = true;
            let bscSlices = [];

            for (const bscSlice of cartItem.bscSlices) {
              const foundItem = items.find((x) => x._id === bscSlice.item_id);

              if (!foundItem || !foundItem.isAvailable) {
                isValid = false;
                break;
              }

              bscSlices.push({
                item: foundItem,
                qty: bscSlice.qty,
              });
            }

            if (!isValid) {
              // clear source cart
              updateCartLocalStorage();
              processedCart = [];

              break;
            }

            // check if cartItem accessories id changed or not
            isValid = true;
            let accessories = [];
            for (const accId of cartItem.accessories) {
              const foundAcc = accessoriesData.find((x) => x._id === accId);
              if (!foundAcc) {
                isValid = false;
                break;
              }
              accessories.push(foundAcc);
            }

            if (!isValid) {
              // clear source cart
              updateCartLocalStorage();
              processedCart = [];
              break;
            }

            // find cart item price
            let sum = 0;

            bscSlices.forEach((bscSlice) => {
              sum += bscSlice.item.price * bscSlice.qty;
            });
            accessories.forEach((acc) => {
              sum += acc.price;
            });

            processedCart.push({
              type: cartItem.type,
              item_id: null,
              itemName: "Birthday Slices Cake",
              itemImagePath: null,
              price: sum,
              qty: cartItem.qty,
              selectedSize: null,
              bscSlices,
              slices: [],
              details: [],
              accessories: accessories,
              customFondant: null,
            });
          } else {
            let foundItem;

            if (cartItem.type === "Item") {
              foundItem = items.find((item) => item._id === cartItem.item_id);
            } else {
              foundItem = accessoriesData.find(
                (acc) => acc._id === cartItem.item_id
              );
            }

            if (!foundItem) {
              // clear source cart
              updateCartLocalStorage();
              processedCart = [];

              break;
            }

            if (!foundItem.isAvailable) {
              // clear source cart
              updateCartLocalStorage();
              processedCart = [];

              break;
            }

            // check if cartItem sizes id changed or not
            // and only check if the cart item has selected size
            let selectedSize;
            if (cartItem.selectedSize) {
              selectedSize = foundItem.sizes.find(
                (x) => x._id === cartItem.selectedSize
              );

              if (!selectedSize) {
                // clear source cart
                updateCartLocalStorage();
                processedCart = [];

                break;
              }
            }

            // check if cartItem slices id changed or not
            let isValid = true;
            let slices = [];

            for (const slice of cartItem.slices) {
              const foundSlice = foundItem.slices.find(
                (x) => x._id === slice._id
              );

              if (!foundSlice) {
                isValid = false;
                break;
              }

              let filteredList = [];
              for (let selectedFlavour of slice.selectedFlavours) {
                const foundFlavour = foundSlice.flavours.find(
                  (x) => x._id === selectedFlavour.flavour_id
                );
                if (foundFlavour) {
                  if (!foundFlavour.isAvailable) {
                    isValid = false;
                    break;
                  }

                  const value = {
                    flavour: foundFlavour,
                    qty: selectedFlavour.qty,
                  };
                  filteredList.push(value);
                }
              }

              slices.push({
                _id: foundSlice._id,
                name: foundSlice.name,
                selectedFlavours: filteredList,
              });
            }

            if (!isValid) {
              // clear source cart
              updateCartLocalStorage();
              processedCart = [];

              break;
            }

            // check if cartItem selections id changed or not
            isValid = true;
            let details = [];

            for (const selection of cartItem.details) {
              const foundSelection = foundItem.selections.find(
                (x) => x._id === selection._id
              );

              if (!foundSelection) {
                isValid = false;
                break;
              }

              const itemIds = selection.items.map((item) => item._id);

              const processedItems = foundSelection.items.filter((item) => {
                return itemIds.indexOf(item._id) !== -1;
              });

              details.push({
                _id: foundSelection._id,
                name: foundSelection.name,
                items: processedItems,
              });
            }

            if (!isValid) {
              // clear source cart
              updateCartLocalStorage();
              processedCart = [];

              break;
            }

            // check if cartItem accessories id changed or not
            isValid = true;
            let accessories = [];

            for (const accId of cartItem.accessories) {
              const foundAcc = foundItem.accessories.find(
                (x) => x._id === accId
              );

              if (!foundAcc) {
                isValid = false;
                break;
              }

              accessories.push(foundAcc);
            }

            if (!isValid) {
              // clear source cart
              updateCartLocalStorage();
              processedCart = [];

              break;
            }

            // find cart item price
            let sum = 0;

            if (cartItem.type === "Item") {
              // slices part
              slices.forEach((slice) => {
                sum += slice.selectedFlavours.reduce(
                  (accumulator, currentValue) =>
                    accumulator +
                    currentValue.flavour["price"] * currentValue.qty,
                  0
                );
              });

              // selections part
              details.forEach((detail) => {
                sum += detail.items.reduce(
                  (accumulator, currentValue) =>
                    accumulator + currentValue["price"],
                  0
                );
              });

              // accessories part
              accessories.forEach((acc) => {
                sum += acc.price;
              });

              // sizes part
              if (selectedSize) sum += selectedSize.price;
            }

            const price = foundItem.price + sum;

            processedCart.push({
              type: cartItem.type,
              item_id: foundItem._id,
              itemName: foundItem.name,
              itemImagePath: foundItem.imagePath,
              price: price,
              qty: cartItem.qty,
              selectedSize: selectedSize,
              slices: slices,
              details: details,
              accessories: accessories,
              customFondant: cartItem.customFondant,
            });
          }
        }
      } catch (err) {
        // clear source cart
        updateCartLocalStorage();
        processedCart = [];
      }
    }

    if (processedCart.length !== 0) setCart(processedCart);
  };

  const addToCart = (cartItem) => {
    setCart((prevState) => {
      if (!prevState) {
        updateCartLocalStorage([cartItem]);
        return [cartItem];
      }

      updateCartLocalStorage([...prevState, cartItem]);
      return [...prevState, cartItem];
    });
  };

  const updateCartItemQty = (index, qty) => {
    setCart((prevState) => {
      const updatedCart = [
        ...prevState.slice(0, index),
        {
          ...prevState[index],
          qty: qty,
        },
        ...prevState.slice(index + 1),
      ];

      updateCartLocalStorage(updatedCart);
      return updatedCart;
    });
  };

  const removeCartItem = (index) => {
    setCart((prevState) => {
      const updatedCart = [
        ...prevState.slice(0, index),
        ...prevState.slice(index + 1),
      ];

      updateCartLocalStorage(updatedCart);
      return updatedCart;
    });
  };

  const clearCart = () => {
    setCart();
    updateCartLocalStorage();
  };

  return (
    <ThemeProvider theme={theme}>
      <Suspense fallback={<div></div>}>
        <TopBar
          categories={categories}
          cart={cart}
          toggleDrawer={toggleDrawer}
        />
        <SideDrawer
          categories={categories}
          isOpened={isSideDrawerOpened}
          toggleDrawer={toggleDrawer}
        />
        <Switch>
          <Route path="/" exact component={Home}></Route>
          <Route path="/contact" exact component={Contact} />
          <Route
            path="/cart"
            exact
            render={(props) => (
              <Cart
                cart={cart}
                updateCartItemQty={updateCartItemQty}
                removeCartItem={removeCartItem}
                clearCart={clearCart}
                {...props}
              />
            )}
          ></Route>
          <Route path="/about-us" exact component={AboutUs} />
          <Route path="/privacy-policy" exact component={PrivacyPolicy} />
          <Route path="/terms-of-use" exact component={TermsOfUse} />
          <Route
            path="/birthday-slices-cake"
            exact
            render={(props) => (
              <BirthdaySlicesCake addToCart={addToCart} {...props} />
            )}
          />
          <Route path="/custome-cake" exact component={CustomeCake} />
          <Route
            path="/two-tier-mille-crepes"
            exact
            component={TwoTierMilleCrepes}
          />
          <Route exact path="/accessories" component={Accessories}></Route>
          <Route
            path="/accessories/:accessoryName/:accessoryId"
            render={(props) => (
              <AccessoryDetails addToCart={addToCart} {...props} />
            )}
          ></Route>
          <Route exact path="/products/:category" component={Products}></Route>
          <Route
            path="/products/:productName/:productId"
            render={(props) => (
              <ProductDetails addToCart={addToCart} {...props} />
            )}
          ></Route>
          <Route component={NotFound} />
        </Switch>
        <Footer />
      </Suspense>
    </ThemeProvider>
  );
}

export default App;
