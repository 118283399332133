import React, { useState } from "react";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import { Link } from "react-router-dom";
import logo from "../assets/logo-2.png";
import { FaCaretDown } from "react-icons/fa";
import Dropdown from "./Dropdown";
import IconButton from "@material-ui/core/IconButton";
import LocalMallOutlinedIcon from "@material-ui/icons/LocalMallOutlined";
import MenuIcon from "@material-ui/icons/Menu";
import { makeStyles } from "@material-ui/core/styles";
import Badge from "@material-ui/core/Badge";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => {
  return {
    appBar: {
      height: "5rem",
      padding: 0,
      [theme.breakpoints.down(768)]: {
        height: "3rem",
      },
    },
    navbarLink: {
      fontWeight: 700,
      fontFamily: '"Quicksand", sans-serif',
      color: "#493437",
      display: "flex",
      alignItems: "center",
      textDecoration: "none",
      padding: "0 1rem",
      height: "5rem",
      "&:hover": {
        color: "#e75c6b",
        transition: "0.2s ease-in-out",
      },
    },
    menuBar: {
      display: "none",
      [theme.breakpoints.down(768)]: {
        display: "block",
      },
    },
    navMenu: {
      display: "flex",
      alignItems: "center",
      [theme.breakpoints.down(768)]: {
        display: "none",
      },
    },
  };
});

export default function TopBar(props) {
  const classes = useStyles();

  const history = useHistory();

  const [isDropdown, setIsDropdown] = useState(false);

  const handleClick = (event) => {
    event.preventDefault();
  };

  const onMouseEnter = () => {
    setIsDropdown(true);
  };

  const onMouseLeave = () => {
    setIsDropdown(false);
  };

  const bagQty =
    props.cart &&
    props.cart.reduce(
      (accumulator, currentValue) => accumulator + currentValue["qty"],
      0
    );

  return (
    <AppBar position="absolute" color="transparent" elevation={0}>
      <Toolbar
        style={{ justifyContent: "space-around" }}
        className={classes.appBar}
      >
        <IconButton
          style={{ color: "#493437" }}
          edge="start"
          color="inherit"
          className={classes.menuBar}
          onClick={props.toggleDrawer(true)}
        >
          <MenuIcon style={{ fontSize: 28 }} />
        </IconButton>

        <Link to="/" className={classes.navbarLink}>
          <img
            src={logo}
            alt="doux-logo"
            style={{ width: "104px", height: "52px" }}
          />
        </Link>

        <div className={classes.navMenu}>
          <Link to="/" className={classes.navbarLink}>
            Home
          </Link>
          <div
            style={{ height: "100%" }}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
          >
            <Link
              to="/products"
              className={classes.navbarLink}
              onClick={handleClick}
            >
              Products<FaCaretDown></FaCaretDown>
            </Link>
            {isDropdown && <Dropdown items={props.categories} />}
          </div>
          <Link to="/birthday-slices-cake" className={classes.navbarLink}>
            Birthday Slices Cake
          </Link>
          <Link to="/custome-cake" className={classes.navbarLink}>
            Custome Cake
          </Link>
          <Link to="/two-tier-mille-crepes" className={classes.navbarLink}>
            Two Tier Mille Crepes
          </Link>
          <Link to="/contact" className={classes.navbarLink}>
            Contact
          </Link>
          <Link to="/about-us" className={classes.navbarLink}>
            About Us
          </Link>
        </div>

        <IconButton
          style={{ color: "#493437" }}
          onClick={() => history.push("/cart")}
        >
          <Badge badgeContent={bagQty} color="primary">
            <LocalMallOutlinedIcon style={{ fontSize: 28 }} />
          </Badge>
        </IconButton>
      </Toolbar>
    </AppBar>
  );
}
