import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import _ from "lodash";

const useStyles = makeStyles((theme) => {
  return {
    dropdownMenu: {
      zIndex: 10,
      width: 400,
      position: "absolute",
      top: 64,
      listStyle: "none",
      padding: 0,
      textAlign: "start",
      "& li": {
        cursor: "pointer",
      },
    },
    dropdownLink: {
      backgroundColor: "#ffeae9",
      fontFamily: "'Quicksand', sans-serif",
      display: "block",
      textDecoration: "none",
      color: "#493437",
      padding: "12px 16px",
      "&:hover": {
        color: "#e75c6b",
      },
    },
  };
});

const Dropdown = (props) => {
  const classes = useStyles();

  const [isOpened, setIsOpened] = useState(false);

  return (
    <ul
      onClick={() => setIsOpened(!isOpened)}
      className={classes.dropdownMenu}
      style={{ backgroundColor: "#ffeae9" }}
    >
      <Grid container>
        {props.items.map((item) => {
          return (
            <Grid item xs={6}>
              <li key={item._id}>
                <NavLink
                  to={`/products/${_.kebabCase(item.name)}`}
                  onClick={() => setIsOpened(false)}
                  className={classes.dropdownLink}
                >
                  {item.name}
                </NavLink>
              </li>
            </Grid>
          );
        })}
        <li>
          <NavLink
            to={`/accessories`}
            onClick={() => setIsOpened(false)}
            className={classes.dropdownLink}
          >
            Accessories
          </NavLink>
        </li>
      </Grid>
    </ul>
  );
};

export default Dropdown;
