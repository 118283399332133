import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

const usePageTracking = () => {
  const location = useLocation();
  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    setInitialized(true);
  }, []);

  useEffect(() => {
    const gtag = window.gtag;

    if (initialized) {
      gtag("config", "G-JD52FVCT65");
    }
  }, [initialized, location]);
};

export default usePageTracking;
